import { Assets } from '@itero/assets-bff-sdk';
import { utils } from '../utils';

const getAssetSdk = () => {
  if (!utils) {
    return null;
  }
  const assetManagerUrl = utils.getAssetManagerUrl();
  if (!assetManagerUrl) {
    return null;
  }

  return new Assets(assetManagerUrl);
};

const getAsset = async (assetReferenceType, isModelCompare) => {
  const assets = getAssetSdk();
  if (!assets) {
    return null;
  }
  const orderId = utils.getOrderId(isModelCompare);
  const rxGuid = utils.getRxGuid();
  const requestModel = {
    assetReferenceType: assetReferenceType,
    orderId: orderId,
    rxId: rxGuid,
  };
  const accessToken = utils.getAccessToken();
  const asset = await assets.getAssets(requestModel, accessToken);
  return { arrayBuffer: () => asset };
};

export const assetManagerRequests = {
  getAsset,
};
