import { cloneDeep, get, isEqual, keys, set, values, isEmpty } from 'lodash';
import { OrthographicCamera } from 'three';
import {
  cacheKeys,
  cacheManager,
  createChromaticMaterial,
  eventBus,
  extractItrModel,
  getCameraPosByCaseType,
  getJawByObjectKey,
  getModel,
  getCompareModel,
  getPhotosFileByEnv,
  globalEventsKeys,
  hostCommunicationManager,
  isNiriEnabled,
  isTextureMappingExistInGeometry,
  logger,
  OutgoingMessagesKeys,
  preparePhotosData,
  settingsManager,
  utils,
  requestsManager,
  modelTypes,
  extendMeshAndGeometry,
  getExistingJawsFromModel,
  JAWS_ENUM,
} from '@web-3d-tool/shared-logic';
import * as configuartion from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import createMiddleware from '../../../middlewareHelper';
import * as AT from '../../actionTypes';
import {
  loadingModelError,
  modelLoaded,
  compareModelLoaded,
  setIsThreejsObjectsReady,
  setMetadata,
  setCompareMetadata,
  setModelId,
  setCompareModelId,
  setModelIsLoading,
  setReadonlyStage,
  setRenderingReadonlyStage,
  setRenderingStage,
  setResetCameraRotationOnUpdate,
  setTextures,
  setCompareTextures,
  modelCompareActive,
  modelCompareSync,
  loadNiri,
} from './renderer.actions';
import rendererLogic from './renderer.logic';

const defaultModelMaterial = createChromaticMaterial();

let imperativeThreeObjects = {};

//******************************
const feature = AT.RENDERER;
//******************************

/**
 * This function will be invoked for every action and not only for
 * renderer actions. ({meta:{feature:AT.RENDERER}})
 * The reason we use it is, because there are actions that being dispatch from
 * the plugins that should be handled here in the renderer
 * @param {action,dispatch,getState} param0
 */
export const goThroughOverride = async ({ action, dispatch, getState }) => {
  const { payload, type, meta } = action;
  const { ORIGIN, COMPARE } = modelTypes;

  switch (type) {
    case AT.CHANGE_BASE_MATERIAL:
      {
        const { metadata: currentMetadata, isModelCompareActive, compareMetadata } = getState().renderer;

        const getMetadataForModel = (metaDataForModel, cacheKey) => {
          const { material } = payload;
          const { uuid, type } = material;
          const geometriesToChange = rendererLogic.getGeometriesWithMaterialAndColorArray(metaDataForModel);
          const metadata = rendererLogic.setMaterialToMetadata({
            metadata: metaDataForModel,
            material: { uuid, type },
            geometriesToChange,
          });

          if (!cacheManager.get(`${cacheKey}.${uuid}`)) {
            cacheManager.set(`${cacheKey}.${uuid}`, material);
          }

          return metadata;
        };

        if (isModelCompareActive && compareMetadata) {
          dispatch(
            setMetadata({ metadata: getMetadataForModel(currentMetadata, cacheKeys.MATERIALS) }),
            setCompareMetadata({ metadata: getMetadataForModel(compareMetadata, cacheKeys.COMPARE_MATERIALS) })
          );
        } else {
          dispatch(setMetadata({ metadata: getMetadataForModel(currentMetadata, cacheKeys.MATERIALS) }));
        }
      }
      break;

    case AT.ADD_TEXTURE:
      {
        const {
          renderer: {
            metadata: { textures },
          },
        } = getState();

        const newTexturesArr = [...textures, payload];

        dispatch(setTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.REMOVE_TEXTURE:
      {
        const {
          renderer: {
            metadata: { textures },
          },
        } = getState();

        const { pluginId, name } = payload;

        const newTexturesArr = textures.filter(({ pluginId: pId, name: n }) => pId !== pluginId && n !== name);

        dispatch(setTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.ADD_MULTIPLE_TEXTURES:
      {
        const {
          renderer: {
            metadata: { textures },
          },
        } = getState();

        const newTexturesArr = [...textures, ...payload.textures];

        dispatch(setTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.REMOVE_MULTIPLE_TEXTURES:
      {
        const {
          renderer: {
            metadata: { textures },
          },
        } = getState();

        const { textures: removeTextures } = payload;

        const newTexturesArr = textures.filter(
          ({ pluginId: pId, name: n }) =>
            !removeTextures.some(({ pluginId: rpId, name: rn }) => pId === rpId && n === rn)
        );

        dispatch(setTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.ADD_COMPARE_TEXTURE:
      {
        const {
          renderer: {
            compareMetadata: { textures },
          },
        } = getState();

        const newTexturesArr = (textures && [...textures, payload]) || [];

        dispatch(setCompareTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.REMOVE_COMPARE_TEXTURE:
      {
        const {
          renderer: {
            compareMetadata: { textures },
          },
        } = getState();

        const { pluginId, name } = payload;

        const newTexturesArr =
          (textures && textures.filter(({ pluginId: pId, name: n }) => pId !== pluginId && n !== name)) || [];

        dispatch(setCompareTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.ADD_MULTIPLE_COMPARE_TEXTURES:
      {
        const {
          renderer: {
            compareMetadata: { textures },
          },
        } = getState();

        const newTexturesArr = (textures && [...textures, ...payload.textures]) || [];

        dispatch(setCompareTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.REMOVE_MULTIPLE_COMPARE_TEXTURES:
      {
        const {
          renderer: {
            compareMetadata: { textures },
          },
        } = getState();

        const { textures: removeTextures } = payload;

        const newTexturesArr =
          textures.filter(
            ({ pluginId: pId, name: n }) =>
              !removeTextures.some(({ pluginId: rpId, name: rn }) => pId === rpId && n === rn)
          ) || [];

        dispatch(setCompareTextures({ textures: newTexturesArr }));
      }
      break;

    case AT.GET_MODEL_OBJECTS_VISIBILITY:
      {
        const {
          renderer: { metadata },
        } = getState();

        const { cb } = payload;
        const visibilityObject = rendererLogic.getVisibilityObjectFromMetadata(metadata);
        cb(visibilityObject);
      }
      break;

    case AT.CHANGE_MODEL_OBJECTS_VISIBILITY:
      {
        const { metadata: currentMetadata, compareMetadata } = getState().renderer;

        const modelTypes = payload.modelTypes || [];

        const getMetadataForModel = (data) => {
          const currentVisibilityValues = rendererLogic.getVisibilityObjectFromMetadata(data);
          const newVisibilityValues = payload;
          const metadata = rendererLogic.changeMetadataByVisibilityObject(
            currentVisibilityValues,
            newVisibilityValues,
            data,
            payload.isToggleModeActive
          );
          return { metadata, currentVisibilityValues };
        };

        const metadataObjects = {
          [ORIGIN]: {
            get: getMetadataForModel,
            set: setMetadata,
            props: {
              cacheKey: cacheKeys.VISIBILITY_OBJECT,
              metadata: currentMetadata,
            },
          },
          [COMPARE]: {
            get: getMetadataForModel,
            set: setCompareMetadata,
            props: {
              cacheKey: cacheKeys.COMPARE_VISIBILITY_OBJECT,
              metadata: compareMetadata,
            },
          },
        };

        modelTypes.forEach((modelType) => {
          const { get, set, props } = metadataObjects[modelType];
          const { metadata: currentModelMetadata, currentVisibilityValues } = get(props.metadata);

          const visibilityObject = rendererLogic.getVisibilityObjectFromMetadata(currentModelMetadata);

          if (!isEqual(visibilityObject, currentVisibilityValues)) {
            const actions = [set({ metadata: currentModelMetadata })];
            if (action.meta.plugin === 'jaws') {
              const {
                jaws: { pluginParameters: jawsState },
              } = getState().plugins;

              logger
                .log(`Navigating jaws: ${JSON.stringify(currentVisibilityValues)}`)
                .to('host')
                .data({ module: 'renderer.middleware' })
                .end();

              dispatch(actions);
              if (modelType === ORIGIN) {
                const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT);
                eventBus.raiseEvent(globalEventsKeys.JAWS_CHANGED, { visibilityObject, jawsState });
              }
            } else {
              dispatch(actions);
            }
          }
        });
      }
      break;

    case AT.CHANGE_MODEL_OBJECTS_MULTIBITE:
      {
        const { multiBiteActive, currentBite } = payload;
        const { metadata: currentMetadata } = getState().renderer;
        const model = cacheManager.get(cacheKeys.MODEL);

        const metadata = rendererLogic.applyMultibiteTransformation(
          model,
          currentMetadata,
          multiBiteActive,
          currentBite
        );

        const actionsArray = [setMetadata({ metadata })];

        dispatch(actionsArray);
        eventBus.raiseEvent(globalEventsKeys.MULTIBITE_CHANGED, { multiBiteActive });
      }
      break;

    case AT.SPLIT_RENDERING_STAGE:
      {
        const { stage } = payload;
        dispatch([
          setRenderingStage({ stage: cloneDeep(stage) }),
          setRenderingReadonlyStage({ readonlyStage: cloneDeep(stage) }),
        ]);
      }
      break;

    case AT.RESET_STAGE:
      {
        const { stage } = payload;
        dispatch([setRenderingStage({ stage }), setRenderingReadonlyStage({ readonlyStage: cloneDeep(stage) })]);
      }
      break;

    case AT.ACTIVATE_TOGGLE_MODE:
      {
        const { stage } = payload;
        const clonedStage = cloneDeep(stage);

        dispatch([
          setRenderingStage({ stage }),
          setRenderingReadonlyStage({ readonlyStage: clonedStage }),
          setResetCameraRotationOnUpdate(true),
        ]);
      }
      break;

    case AT.DEACTIVATE_TOGGLE_MODE:
      {
        dispatch([setResetCameraRotationOnUpdate(false)]);
      }
      break;

    case AT.CAMERA_STOPPED_MOVING:
      {
        const {
          camera: { position, up, zoom },
          arrayIndex,
          inArrayIndex,
        } = payload;
        const updatedCameraProps = {
          position: values(position),
          up: values(up),
          zoom,
        };
        const {
          renderer: { readonlyStage },
        } = getState();

        set(readonlyStage, [arrayIndex, inArrayIndex], updatedCameraProps);
        dispatch(setReadonlyStage({ readonlyStage }));
        eventBus.raiseEvent(globalEventsKeys.CAMERA_STOPPED_MOVING, { ...payload });
      }
      break;

    case AT.ACTIVEATE_MODEL_COMPARE:
      {
        const { isModelCompareActive, isModelCompareInDifferentMode } = payload;
        dispatch(modelCompareActive({ isModelCompareActive, isModelCompareInDifferentMode }));
      }
      break;

    case AT.MODEL_COMPARE_TOGGLE_SYNC:
      {
        const { isModelSynced } = payload;
        dispatch(modelCompareSync({ isModelSynced }));
      }
      break;

    case AT.SET_METADATA:
      {
        let visibilityObject = rendererLogic.getVisibilityObjectFromMetadata(payload.metadata);
        delete visibilityObject[payload.metadata.jawMissing];
        visibilityObject = Object.keys(
          Object.fromEntries(Object.entries(visibilityObject).filter(([objectKey, isVisible]) => isVisible))
        );
        cacheManager.set(cacheKeys.VISIBILITY_OBJECT, visibilityObject);
      }
      break;

    case AT.SET_COMPARE_METADATA:
      {
        const { compareMetadata } = payload;
        let compareVisibilityObject = compareMetadata && rendererLogic.getVisibilityObjectFromMetadata(compareMetadata);
        compareVisibilityObject =
          compareVisibilityObject &&
          Object.keys(
            Object.fromEntries(Object.entries(compareVisibilityObject).filter(([objectKey, isVisible]) => isVisible))
          );
        cacheManager.set(cacheKeys.COMPARE_VISIBILITY_OBJECT, compareVisibilityObject);
      }
      break;

    case AT.LOAD_MODEL:
      {
        dispatch(setModelIsLoading(true));
        const arr = [];
        const appVersion = utils.getAppVersion();
        let model = null;
        let caseType = null;

        try {
          cacheManager.set(cacheKeys.MODEL, model);

          // payload will contain itr data only in debug mode
          if (!payload?.itrFileData) {
            model = await getModel({});
          } else {
            model = await extractItrModel(payload.itrFileData);
          }

          caseType = settingsManager.getConfigValue(configuartion.mode);

          if (!caseType) {
            const { pathname } = window.location;
            caseType = pathname.toLowerCase().includes('itero') ? 'resto' : 'ortho';
          }

          model.caseType = caseType;

          cacheManager.set(cacheKeys.MODEL, model);

          const { id } = model;
          arr.push(...[setModelId({ id }), modelLoaded({ ...payload, model }), setModelIsLoading(false)]);

          hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.MODEL_LOADED, {
            success: true,
            version: appVersion,
            timeToRender: logger.timeEnd('AT.APP_LOADED'),
          });
        } catch (err) {
          if (err.name === 'max_attempts_exceeded') {
            hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.OPEN_EXCEEDED_DOWNLOAD_ATTEMPTS_POPUP, {
              success: false,
              version: appVersion,
            });
          }
          arr.push(loadingModelError({ err }));
        }

        dispatch(arr);
      }
      break;

    case AT.LOAD_COMPARE_MODEL:
      {
        dispatch(setModelIsLoading(true));
        const arr = [];
        const appVersion = utils.getAppVersion();
        const { orderId, isModelCompareOrtho } = payload || {};
        let compare_model = null;

        try {
          cacheManager.set(cacheKeys.COMPARE_MODEL, compare_model);
          compare_model = await getCompareModel(orderId);

          const modelCaseType = isModelCompareOrtho ? 'ortho' : 'resto';
          compare_model.caseType = modelCaseType;

          cacheManager.set(cacheKeys.COMPARE_MODEL, compare_model);

          const { id } = compare_model;
          arr.push(
            ...[
              setCompareModelId({ id }),
              compareModelLoaded({ ...payload, compareModel: compare_model, orderId }),
              setModelIsLoading(false),
            ]
          );
          hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.COMPARE_MODEL_LOADED, {
            success: true,
            version: appVersion,
            timeToRender: logger.timeEnd('AT.APP_LOADED'),
          });
        } catch (err) {
          if (err.name === 'max_attempts_exceeded') {
            hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.OPEN_EXCEEDED_DOWNLOAD_ATTEMPTS_POPUP);
          } else {
            eventBus.raiseEvent(globalEventsKeys.MODEL_LOAD_FAIL, { err });
          }
          arr.push(loadingModelError({ err }));
        }
        const isModelUnloaded = cacheManager.get(cacheKeys.MODEL_UNLOADED);
        if (isModelUnloaded) {
          logger
            .info(`Compare model load interrupted by user action and was unloaded.`)
            .data({ module: 'renderer.middleware' })
            .end();
          cacheManager.set(cacheKeys.MODEL_UNLOADED, false);
          dispatch(setModelIsLoading(false));
        } else {
          dispatch(arr);
        }
      }
      break;

    case AT.UNLOAD_COMPARE_MODEL:
      {
        dispatch(setModelIsLoading(true));

        const arr = [];
        let compare_model = null;

        try {
          cacheManager.set(cacheKeys.COMPARE_MODEL, compare_model);

          const caseType = settingsManager.getConfigValue(configuartion.mode);
          const stage = [[{ cameraProps: getCameraPosByCaseType(caseType, 'front') }]];
          const clonedStage = cloneDeep(stage);

          arr.push(
            ...[
              setCompareMetadata({
                metadata: {
                  upper_jaw: {
                    visible: false,
                    upper_jaw: {
                      visible: false,
                    },
                  },
                  lower_jaw: {
                    visible: false,
                    lower_jaw: {
                      visible: false,
                    },
                  },
                  textures: [],
                  bite: {
                    active: false,
                  },
                },
              }),
              setCompareModelId({ id: undefined }),
              setRenderingStage({ stage }),
              setRenderingReadonlyStage({ readonlyStage: clonedStage }),
              setModelIsLoading(false),
            ]
          );
        } catch (err) {
          arr.push(loadingModelError({ err }));
        }

        dispatch(arr);

        eventBus.raiseEvent(globalEventsKeys.MODEL_UNLOADED);
      }
      break;

    case AT.LOAD_NIRI:
      {
        const { niriFileData, includesNiriData, isModelCompare, compareOrderId } = payload || {};

        const loadNiriData = async () => {
          let niriData = null;
          let modelUnloaded = false;

          eventBus.subscribeToEvent(globalEventsKeys.MODEL_UNLOADED, () => {
            modelUnloaded = true;
          });

          const params = isModelCompare
            ? {
                loggerTimeString: 'NIRI_COMPARE_LOADED',
                niriDataCacheKey: cacheKeys.COMPARE_NIRI_DATA,
                niriFilePath: { orderId: compareOrderId },
                eventBusKey: globalEventsKeys.NIRI_COMPARE_LOADED,
                dataJsonCacheKey: cacheKeys.COMPARE_DATA_JSON,
                imagesMetadataCacheKey: cacheKeys.COMPARE_IMAGES_META_DATA,
              }
            : {
                loggerTimeString: 'NIRI_LOADED',
                niriDataCacheKey: cacheKeys.NIRI_DATA,
                niriFilePath: requestsManager.getNiriFilePath(),
                eventBusKey: globalEventsKeys.NIRI_LOADED,
                dataJsonCacheKey: cacheKeys.DATA_JSON,
                imagesMetadataCacheKey: cacheKeys.IMAGES_META_DATA,
              };

          const {
            loggerTimeString,
            niriDataCacheKey,
            niriFilePath,
            eventBusKey,
            dataJsonCacheKey,
            imagesMetadataCacheKey,
          } = params;

          logger.time(loggerTimeString);
          try {
            cacheManager.set(niriDataCacheKey, niriData);

            if (!isNiriEnabled()) return;
            // payload will contain niri data only in debug mode
            if (!includesNiriData) {
              const zippedPhotosFile = await getPhotosFileByEnv(niriFilePath, isModelCompare);
              niriData = await preparePhotosData(zippedPhotosFile, dataJsonCacheKey, imagesMetadataCacheKey);
            } else {
              niriFileData &&
                (niriData = await preparePhotosData(niriFileData, dataJsonCacheKey, imagesMetadataCacheKey));
            }
            cacheManager.set(niriDataCacheKey, niriData);
          } catch (err) {
            logger
              .error(err.message)
              .data({ module: 'renderer.middleware' })
              .end();
            niriData = null;
            hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.NIRI_LOADED, {
              success: false,
            });
          }

          !modelUnloaded && eventBus.raiseEvent(eventBusKey, niriData);
        };

        await loadNiriData();
      }
      break;

    default:
    // no default
  }
};

export const middleware = async ({ action, dispatch, getState }) => {
  const { payload, type } = action;

  switch (type) {
    case AT.MODEL_LOADED:
      {
        const {
          renderer: { metadata: currentMetadata, compareMetadata },
        } = getState();
        const { isModelCompare, compareModel, model, niriFileData, includesNiriData, compareOrderId } = payload || {};
        const caseType = settingsManager.getConfigValue(configuartion.mode);
        const modelCompareCaseType = payload.isModelCompareOrtho ? 'ortho' : 'resto';
        const loadedModel = compareModel || model;
        const isAOHS = utils.isAOHSEnv();

        if (isModelCompare && compareModel) {
          compareModel.objects = Object.fromEntries(
            Object.entries(loadedModel.objects).filter(([key, value]) => !key.includes('margin_line'))
          );
        }

        extendMeshAndGeometry(loadedModel);

        const metadata = keys(loadedModel.objects).reduce(
          (acc, key) => {
            const jawKey = getJawByObjectKey(key);
            const modelObject = loadedModel.objects[key];

            if (isEmpty(modelObject)) {
              return acc;
            }

            const isVisible = !key.match(/(pretreatment|denture_copy_scan|emergence_profile)/i);
            const hasColor = !!get(loadedModel, `objects.${key}.attributes.color`);
            const hasTextures = isTextureMappingExistInGeometry(loadedModel.objects[key]);

            const material = loadedModel.objects[key].material || defaultModelMaterial;

            if (material) {
              cacheManager.set(
                `${isModelCompare ? cacheKeys.COMPARE_MATERIALS : cacheKeys.MATERIALS}.${material.uuid}`,
                material
              );
            }

            const { upper_jaw, lower_jaw } = getExistingJawsFromModel(loadedModel.objects) || {};
            const jaws = { upper_jaw, lower_jaw };
            const jawMissing = Object.keys(jaws).find((key) => !jaws[key]);

            set(acc, `${jawKey}.${key}`, { visible: isVisible, material, hasColor, hasTextures });
            set(acc, `${jawKey}.visible`, true);
            set(acc, `jawMissing`, jawMissing);

            return acc;
          },
          isModelCompare ? compareMetadata : currentMetadata
        );

        cacheManager.set(isModelCompare ? cacheKeys.COMPARE_MODEL : cacheKeys.MODEL, loadedModel);

        logger
          .info(`itr file version ${loadedModel.itrFileVersion}`)
          .data({ module: 'renderer.middleware' })
          .end();

        const { id } = loadedModel;
        const stage = isModelCompare
          ? [
              [
                { cameraProps: getCameraPosByCaseType(modelCompareCaseType, 'front') },
                { cameraProps: getCameraPosByCaseType(caseType, 'front') },
              ],
            ]
          : [[{ cameraProps: getCameraPosByCaseType(caseType, 'front') }]];
        const clonedStage = cloneDeep(stage);

        const niriLoadAction = loadNiri({ niriFileData, includesNiriData, isModelCompare, compareOrderId });
        const arr = isModelCompare
          ? [
              setCompareMetadata({ metadata }),
              setCompareModelId({ id }),
              setRenderingStage({ stage }),
              setRenderingReadonlyStage({ readonlyStage: clonedStage }),
            ]
          : [
              setMetadata({ metadata }),
              setModelId({ id }),
              setRenderingStage({ stage }),
              setRenderingReadonlyStage({ readonlyStage: clonedStage }),
            ];

        (!isAOHS || isModelCompare) && arr.push(niriLoadAction);

        dispatch(arr);
      }
      break;

    case AT.IMPERATIVE_THREE_OBJECTS_READY:
      {
        const { objects, split, modelType } = payload;
        imperativeThreeObjects = { ...objects };
        const threeObjects = cacheManager.get(cacheKeys.THREE_OBJECTS) || {
          model: {
            lower_jaw: {},
            upper_jaw: {},
          },
          compare_model: {
            lower_jaw: {},
            upper_jaw: {},
          },
        };

        const { group } = imperativeThreeObjects;
        const meshes = group && group.children.length > 0 && group.children.map((child) => child.clone());

        if (meshes) {
          const filteredMeshes = meshes.filter((mesh) => {
            if (/upper_jaw|lower_jaw|pretreatment|_adjacent$/.exec(mesh.name)) {
              const jawName = getJawByObjectKey(mesh.name);
              mesh.name = jawName;
              return mesh;
            }
            return null;
          });

          filteredMeshes.forEach(
            (mesh) =>
              (threeObjects[modelType][mesh.name] = {
                ...imperativeThreeObjects,
                mesh,
              })
          );
        } else {
          const jawNames = split === 0 ? [JAWS_ENUM.upper_jaw, JAWS_ENUM.lower_jaw] : [JAWS_ENUM.lower_jaw];
          jawNames.forEach((jawName) => {
            threeObjects[modelType][jawName] = { ...threeObjects[modelType][jawName], ...imperativeThreeObjects };
          });
        }

        // ensure correct camera for upper and lower jaws
        Object.values(threeObjects[modelType]).forEach((jaw) => {
          const { scene } = jaw || {};
          if (scene) {
            const relatedCamera = scene.children.find((child) => child instanceof OrthographicCamera);
            jaw.camera = relatedCamera || jaw.camera;
          }
        });

        cacheManager.set(cacheKeys.THREE_OBJECTS, threeObjects);

        const { zoom } = imperativeThreeObjects.camera;
        const { readonlyStage } = getState().renderer;

        //#################################################################################/
        // This is done for automation team so that they will have "initial zoom" to test
        if (get(readonlyStage, '[0][0].cameraProps')) {
          readonlyStage[0][0].cameraProps.zoom = zoom;
        }
        //#################################################################################/

        dispatch([setIsThreejsObjectsReady(true), setRenderingReadonlyStage({ readonlyStage: [...readonlyStage] })]);

        eventBus.raiseEvent(globalEventsKeys.IMPERATIVE_THREE_OBJECTS_READY, { threeObjects });
      }
      break;

    default:
    // no default
  }
};

export default createMiddleware({ feature, goThroughOverride })(middleware);
