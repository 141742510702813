import { once } from 'lodash';
import { Timber } from '@itero/timber';
import { LogLevel, EventType, AppenderType } from '@itero/timber/enums';
import { BIEventsService } from '@itero/timber/bi-events/BIEventsService';
import { biMethods } from './index';
import * as configuration from '../constants/configurationValues.constants';
import { settingsManager } from '../settings-manager';
import { appSettingsManager } from '../app-settings-manager';
import { utils } from '../utils';
import storeCommunicationService from '../store-communication-service/store-communication.service';

let timber = null;
let biTimber = null;
let runOnce = false;
let runOnceBI = false;

export const missingTimberUrlParamError = 'Missing timber url for timberLogger';

// This method updates the value of runOnceBI variable to false, so that new biTimber instance is created
export const updateBiInstance = () => {
  runOnceBI = false;
};

// This method updates the value of runOnce variable to false, so that new timber instance is created
export const updateTimberInstance = () => {
  runOnce = false;
};

const getTimberInstance = () => {
  if (runOnce) {
    return timber;
  }

  const timberUrl = settingsManager.getConfigValue(configuration.timberUrl) || null;
  const companyId = settingsManager.getConfigValue(configuration.companyId) || 'NO_COMPANY_ID_PARAM';
  const userId = settingsManager.getConfigValue(configuration.userId) || 'NO_USER_ID_PARAM';

  if (!timberUrl) {
    console.error(missingTimberUrlParamError);
    runOnce = true;
    return null;
  }

  runOnce = true;

  timber = new Timber({
    appId: 'web-3d-tool',
    appenderTypes: [AppenderType.Remote],
    companyId,
    userId,
    minLogLevel: LogLevel.All,
    requiredFields: [],
    url: timberUrl,
    eventType: EventType.BusinessReport,
  });

  return timber;
};

export const handleTokenUpdate = (config) => {
  const handler = async ({ detail }) => {
    if (detail.context) {
      const { context } = detail;
      const { accessToken } = context;
      if (accessToken && accessToken.length > 0) {
        config.getAccessToken = () => accessToken;
      }
      storeCommunicationService.unsubscribe(handleTokenUpdate);
    }
  };
  return handler;
};

const getBITimberInstance = async () => {
  try {
    if (runOnceBI) {
      return biTimber;
    }

    const { biLoggingEndPoint, environmentName } = appSettingsManager.getAppSettingsByValue(
      'environmentParametersSettings'
    );
    const biTimberUrl = biLoggingEndPoint || null;

    if (!biTimberUrl) {
      console.error(missingTimberUrlParamError);
      runOnceBI = false;
      return null;
    }

    runOnceBI = true;
    const config = {
      appId: 'web-3d-tool',
      url: biTimberUrl,
      env: environmentName,
      maxMessagesBulk: 1,
      sendingInterval: 1000 * 20,
    };

    storeCommunicationService.subscribe(handleTokenUpdate(config));

    biTimber = new BIEventsService(config);

    return biTimber;
  } catch (error) {
    return biTimber;
  }
};

const buildBITimberMessage = (params) => {
  const { additionalFields, biEventType } = params;
  const sessionId =
    settingsManager.getConfigValue(configuration.PlatformBISessionId) ||
    settingsManager.getConfigValue(configuration.SessionId) ||
    null;
  const message = {
    sessionId,
    component: settingsManager.getConfigValue(configuration.layout360) === 'true' ? '360 Hub' : 'Web Viewer',
    sessionType: settingsManager.getConfigValue(configuration.SessionType) || null,
    type: biEventType,
    event: additionalFields,
  };
  return message;
};

const buildTimberMessage = (params) => {
  const { eventName, action, module, type, actor = 'User', value } = params;
  const platform = utils.getEnv() || 'eup';
  const message = {
    eventName,
    module,
    type,
    actor,
    action,
    value: { value: value },
    platform,
  };
  return message;
};

export const logToTimber = ({ timberData }) => {
  const timber = getTimberInstance();
  if (timber) {
    const message = buildTimberMessage(timberData);
    timber.log(LogLevel.All, {}, { extendedParameters: message });
  }
};

export const getTimberUrls = once(async () => {
  const timberUrls = [];
  const timberBiInstance = await getBITimberInstance();
  const timberInstance = await getTimberInstance();

  timberInstance && timberUrls.push(timberInstance.config.url);
  timberBiInstance && timberUrls.push(timberBiInstance.config.url);

  return timberUrls;
});

/**
 * @param {Object.<function>} biMethods - accepts any function from biMethods :
 * - userActionBiLog: (function).
 * - uiNavigationBiLog: (function).
 * - errorReportBiLog: (function).
 * - apiReportBiLog: (function).
 * - specialBiLog: (function).
 * @returns {void} logging BI to Timber.
 */
export const logToTimberBI = async ({ timberData }) => {
  let isSuccess = false;
  const timber = await getBITimberInstance();
  if (timber) {
    const message = buildBITimberMessage(timberData);
    if (!!message.sessionId && isValidSessionID(message.sessionId) && !!message.sessionType) {
      await timber.addBIEvent(message);
      isSuccess = true;
    }
  }
  return isSuccess;
};

export const logButtonClickToTimberBI = async (locationParentObject, objectName) => {
  logToTimberBI(
    biMethods.userActionBiLog({
      objectName: objectName,
      action: 'click',
      objectType: 'button',
      locationParentObject: locationParentObject,
      selectedValue: '',
      automationId: '',
    })
  );
};

const isValidSessionID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};
