import { BufferGeometry, ExtrudeGeometry } from 'three';
import { keys, set } from 'lodash';
import { getJawByObjectKey, JAWS_ENUM } from '@web-3d-tool/shared-logic';
const jaws = [JAWS_ENUM.upper_jaw, JAWS_ENUM.lower_jaw];

const setMaterialToMetadata = ({ metadata, material, geometriesToChange }) => {
  return jaws.reduce((acc, jaw) => {
    keys(metadata[jaw]).forEach((geometryName) => {
      if (geometriesToChange.includes(geometryName)) {
        set(acc, `${jaw}.${geometryName}.material`, material);
      }
    });
    return acc;
  }, metadata);
};

const getGeometriesWithMaterialAndColorArray = (metadata) => {
  const geometriesArray = [];

  jaws.forEach((jaw) => {
    keys(metadata[jaw]).forEach((key) => {
      if (typeof metadata[jaw][key] === 'object' && (metadata[jaw][key].hasColor || metadata[jaw][key].hasTextures)) {
        geometriesArray.push(key);
      }
    });
  });

  return geometriesArray;
};

const getVisibilityObjectFromMetadata = (metadata) => {
  return jaws.reduce((acc, jaw) => {
    const jawObj = metadata[jaw];

    keys(metadata[jaw]).forEach((key) => {
      if (jawObj[key].visible !== undefined) {
        acc[key] = jawObj[key].visible;
      }
    });

    acc[jaw] = metadata[jaw].visible;
    return acc;
  }, {});
};

const changeMetadataByVisibilityObject = (
  currentVisibilityValues,
  newVisibilityValues,
  currentMetadata,
  isToggleModeActive = false
) => {
  const newMetaDataObject = {
    ...currentMetadata,
  };

  [JAWS_ENUM.upper_jaw, JAWS_ENUM.lower_jaw].forEach((jaw) => {
    // check availability of jaw in objects
    if (newVisibilityValues[jaw] !== undefined) {
      newMetaDataObject[jaw].visible = newVisibilityValues[jaw];
    }

    if (isToggleModeActive) {
      Object.keys(newMetaDataObject[jaw]).forEach((key) => {
        if (newMetaDataObject[jaw][key].visible !== undefined) {
          const isJawVisible = newVisibilityValues[jaw];
          const visibilityHasKey = newVisibilityValues.hasOwnProperty(key);
          const isKeyMarginLine = /margin_line/.test(key);
          const isKeyDitch = /ditch_inner|ditch_outer/.test(key);

          newMetaDataObject[jaw][key].visible =
            isJawVisible && visibilityHasKey
              ? newVisibilityValues[key]
              : newVisibilityValues[jaw] && !isKeyMarginLine && !isKeyDitch;
        }
      });
    } else {
      Object.keys(currentVisibilityValues).forEach((key) => {
        let visibilityValue = !!newMetaDataObject[jaw]?.[key]?.visible;
        const jawExistsInMetadata = currentMetadata[jaw]?.[jaw];

        if (newMetaDataObject[jaw]?.[key]) {
          if (newVisibilityValues[key] !== undefined) {
            visibilityValue = newVisibilityValues[key];
          } else if (jawExistsInMetadata) {
            visibilityValue = currentMetadata[jaw][key].visible;
          }

          newMetaDataObject[jaw][key].visible = visibilityValue;
        }
      });
    }
  });

  return newMetaDataObject;
};

const applyMultibiteTransformation = (model, currentMetadata, multiBiteActive, currentBite) => {
  const { objects, multiBite } = model;
  const lowerJawRelatedGeometries = Object.fromEntries(
    Object.entries(objects).filter(([key, object]) => {
      const isObjectBufferGeometry = object instanceof BufferGeometry;
      const isObjectLineGeometry = object?.geometry instanceof ExtrudeGeometry && object?.name === 'margin_line';
      return getJawByObjectKey(key) === JAWS_ENUM.lower_jaw && (isObjectBufferGeometry || isObjectLineGeometry);
    })
  );

  const biteIndex = multiBiteActive ? currentBite : 2;
  const { transformationMatrix, transformationInverseMatrix } = Object.values(multiBite.bites).find(
    (bite) => bite.biteIndex === biteIndex
  );

  let { previousTransformationInverseMatrix } = currentMetadata.bite;

  Object.values(lowerJawRelatedGeometries).forEach((mesh) => {
    const meshGeometry = mesh.name === 'margin_line' ? mesh.geometry : mesh;

    previousTransformationInverseMatrix && meshGeometry.applyMatrix4(previousTransformationInverseMatrix);
    if (multiBiteActive) {
      meshGeometry.applyMatrix4(transformationMatrix);
    }
  });
  previousTransformationInverseMatrix = multiBiteActive ? transformationInverseMatrix : null;

  return { ...currentMetadata, bite: { active: multiBiteActive, previousTransformationInverseMatrix } };
};

export default {
  changeMetadataByVisibilityObject,
  getVisibilityObjectFromMetadata,
  setMaterialToMetadata,
  getGeometriesWithMaterialAndColorArray,
  applyMultibiteTransformation,
};
